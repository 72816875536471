import React, { Fragment, useEffect, useState, useRef } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './thankyou.css'
import { deryptData, encryptData, transactionAPI } from '../../api/Activity'
import { LOCALSTORAGE_KEY } from '../../api/Variables'
import { json } from 'react-router-dom'
import { pushGTMTAGThankYouPage } from '../../api/GTMTag'
import { dateFormatting } from '../../api/Functions'

function Thankyou() {
  const [orderStatus, setOrderStatus] = useState('nothing');
  const initialized = useRef(false);
  const [orderDetails, setOrderDetails] = useState({});
  const [localData, setLocalData] = useState({});
  const [expireDate, setExpireDate] = useState(null);
  // const [paymentMethod, setPaymentMethod] = useState(null);

  var days = 29;

  useEffect(() => {

    if (!initialized.current) {
      initialized.current = true
      userDetails()
      window.scrollTo(0, 0)
    }
    

  }, []);

  const userDetails = async () => {

    if (!localStorage.getItem(LOCALSTORAGE_KEY)) {
   
      window.location.replace('https://www.watcho.com/')
    }
    else {


      try {



        var data = await deryptData(localStorage.getItem(LOCALSTORAGE_KEY));
     
        setLocalData(data)

      
        if ((data.order.PGResponse.SubscriptionParam.FrequencyUnit).toLowerCase() === 'year') {
          days = 365 * parseInt((data.order.PGResponse.SubscriptionParam.Frequency));
        }
        else if ((data.order.PGResponse.SubscriptionParam.FrequencyUnit).toLowerCase() === 'month') {
          days = 29 * parseInt((data.order.PGResponse.SubscriptionParam.Frequency))

        }
        var mydate = new Date(data.order.PGResponse.SubscriptionParam.StartDate);
        mydate = new Date(mydate.setDate(mydate.getDate() + days));
        var expiry_date = dateFormatting(mydate);
        setExpireDate(expiry_date);
        // if (data.order.PGMode == 'Paytm') {
          const queryParams = new URLSearchParams(window.location.search);
          const code = queryParams.get("BANKNAME");
          var BANKTXNID = queryParams.get("BANKTXNID");
          var CHECKSUMHASH = queryParams.get("CHECKSUMHASH");
          var CURRENCY = queryParams.get("CURRENCY");
          var GATEWAYNAME = queryParams.get("GATEWAYNAME");
          var MID = queryParams.get("MID");
          const order_id = queryParams.get("ORDERID");
          var PAYMENTMODE = queryParams.get("PAYMENTMODE");
          var RESPCODE = queryParams.get("RESPCODE");
          var RESPMSG = queryParams.get("RESPMSG");
          const amount = queryParams.get("amount");
          var TransactionNo = queryParams.get("TXNID");
          var TXNDATE = queryParams.get("TXNDATE");

          var SUBS_ID = queryParams.get("SUBS_ID");

          var ord_status = queryParams.get("STATUS");

          var status = '';
          if (ord_status.toLowerCase() == 'txn_success') {
            status = "Committed";
            setOrderStatus('success')
            if ((data.order.PGResponse.SubscriptionParam.FrequencyUnit).toLowerCase() === 'year') {
              pushGTMTAGThankYouPage('payment_success_annual', data, TransactionNo, expiry_date);

            }
            else if ((data.order.PGResponse.SubscriptionParam.FrequencyUnit).toLowerCase() === 'month') {

              pushGTMTAGThankYouPage('payment_success', data, TransactionNo,  expiry_date);

            }

            
          }
          else {
            status = "Failed";
            setOrderStatus('failed')
           
            pushGTMTAGThankYouPage('payment_failed', data, TransactionNo);

          }
          
          var response_data = {
            'BANKNAME': code,
            'BANKTXNID': BANKTXNID,
            'CHECKSUMHASH': CHECKSUMHASH,
            'CURRENCY': CURRENCY,
            'GATEWAYNAME': GATEWAYNAME,
            'MID': MID,
            'ORDERID': order_id,
            'PAYMENTMODE': PAYMENTMODE,
            'RESPCODE': RESPCODE,
            'RESPMSG': RESPMSG,
            'amount': amount,
            'TXNID': TransactionNo,
            'TXNDATE': TXNDATE,
            'SUBS_ID': SUBS_ID,
            'STATUS': ord_status

          }

          var param_data = {

            "OTTSMSID": data.OTTSMSID,
            "MobileNo": data.MobileNo,
            "Source": "NDLP",
            "OrderID": order_id,
            "Amount": parseInt(amount),
            "Status": status,
            "TransactionNo": TransactionNo,
            "PGMode": data.order.PGMode,
            "PGResponse": JSON.stringify(response_data),
            "PGSubscriptionID": SUBS_ID,
            "AutoRenewalStatus": data.AutoRenewalStatus,
            "SubscriptionPlanID": data.SubscriptionPlanID
          }
        


          setOrderDetails({
            transaction_date: TXNDATE,
            transactionid: TransactionNo,
            orderid: order_id,
            amount: amount
          })


        // }
        // else {

        //   var param_data = {

        //     "OTTSMSID": data.OTTSMSID,
        //     "MobileNo": data.MobileNo,
        //     "Source": "NDLP",
        //     "OrderID": '',
        //     "Amount": '',
        //     "Status": 'Committed',
        //     "TransactionNo": null,
        //     "PGMode": 'BILLDESK',
        //     "PGResponse": null,
        //     "PGSubscriptionID": "",
        //     "AutoRenewalStatus": data.AutoRenewalStatus,
        //     "SubscriptionPlanID": data.SubscriptionPlanID
        //   }
        //   // data.SubscriptionPlanID
        //   const queryParams = new URLSearchParams(window.location.search);
        //   const code = queryParams.get("txnBD");
        //   var strArray = code.split(",");
       
        //   var status = '';
        //   var orderData = [];
        //   for (var i = 0; i < strArray.length; i++) {
         
        //     var key_data = strArray[i].split(":");
        //     if (key_data[0] == 'orderid') {
        //       param_data['OrderID'] = key_data[1];
        //     }
        //     else if (key_data[0] == 'amount') {
        //       param_data['Amount'] = parseInt(key_data[1]);
        //     }
        //     else if (key_data[0] == 'transactionid') {
        //       param_data['TransactionNo'] = key_data[1];
        //     }
        //     else if (key_data[0] == 'status') {
        //       status = 'failed';
        //       setOrderStatus('failed')
        //     }
        //     else if (key_data[0] == 'transaction_error_type') {
        //       if (key_data[1] == 'success') {
        //         param_data['Status'] = 'Committed';
        //       }
        //       else {
        //         param_data['Status'] = 'Failed';

        //       }
        //     }


        //     else if (key_data[0] == 'key') {
            
        //       param_data['PGResponse'] = key_data[1];

        //     }



        //     orderData[key_data[0]] = key_data[1];

        //   }
        //   setOrderDetails(orderData)



        // }

        // if (status.toLowerCase() != 'failed') {


          var param = await encryptData(param_data);

          const appData = await transactionAPI(param, data.token);
       
          if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
            // setOrderStatus('success')

          }
          else {
            setOrderStatus('failed')

          }
        // }
        if (localStorage.getItem(LOCALSTORAGE_KEY)) {

          // localStorage.removeItem(LOCALSTORAGE_KEY)
        }



      } catch (error) {

      }
    }
  }

  // const dateFormatting = (date) => {
  //   var mydate = new Date(date);
  //   var month = ["Jan", "Feb", "Mar", "Apr", "May", "June",
  //     "July", "Aug", "Sep", "Oct", "Nov", "Dec"][mydate.getMonth()];
  //   var str = mydate.getDate() + " " + month + ' ' + mydate.getFullYear();
  //   return str;
  // }
  const continuteTowatch = async () => {
    var data = await deryptData(localStorage.getItem(LOCALSTORAGE_KEY));
    pushGTMTAGThankYouPage('continue_to_watch', data);

    window.location.replace('https://www.watcho.com/')

  }
  return (
    <Fragment>
      <Header />

      <div className='policy-wrapper'>
        <div className='policy-cardWrapper' >
          <div className="temp_inner">
            {
              orderStatus !== 'nothing' ?
                <div className='subscribe_form_main'>
                  {
                    orderStatus != 'success' ?
                      <>
                        <span className='subscribe_failed'> <i className="fa fa-times"></i> </span>
                        <h2> Sorry! Your subscription request is failed ! </h2>


                        <div className='cntent_subscribe'>
                          <p> Order ID : {orderDetails.orderid ?? ''} </p>
                        </div>

                        <div className='subscribe_btn'> <a href='https://www.watcho.com/'> Done </a> </div>

                      </>
                      :
                      <>
                        <span className='subscribe_check'> <i className="fa fa-check"></i> </span>
                        <h2> Awesome! You have now subscribed to {localData.planname ?? ''}! </h2>

                        <div className='cntent_subscribe'>
                          <p> Start Date :
                            {
                              localData.order.PGResponse.SubscriptionParam.StartDate ?
                                dateFormatting(localData.order.PGResponse.SubscriptionParam.StartDate)
                                :
                                null
                            }
                          </p>
                          <p> End Date :  {expireDate} </p>
                          <p> Transaction Date : {orderDetails.transaction_date ? dateFormatting(orderDetails.transaction_date) : ''}  </p>
                        </div>

                        <div className='cntent_subscribe'>
                          <p>Transaction ID : {orderDetails.transactionid ?? ''}  </p>
                          <p> Order ID : {orderDetails.orderid ?? ''} </p>
                          <p> Subscription Price : ₹ {orderDetails.amount ?? ''} </p>
                        </div>

                        <div className='subscribe_btn'> <a onClick={e => continuteTowatch()}> Done </a> </div>

                      </>
                  }


                </div>
                :
                <div className='subscribe_form_main'>
                  <span className='subscribe_check'> <i className="fa fa-load"></i> </span>
                  <h2> Please Wait! </h2>
                </div>
            }




          </div>


        </div>
      </div>
      <Footer />

    </Fragment>
  )
}

export default Thankyou