import React, { Fragment } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import './policy.css'
import { Link, useNavigate } from 'react-router-dom'

function Policy() {
  const navigate = useNavigate();

  return (
    <Fragment>
      <Header />  
      <div className='policy-wrapper'>
          <div className='policy-cardWrapper' >
          <h1 className="title"><a  onClick={() => navigate(-1)} className='back-btn'><i className="fa fa-arrow-left"></i></a> Privacy Policy and Terms</h1>
          <div className="temp_inner">
            <p>We are committed to protecting and respecting your privacy. We do collect your personal information in connection with the Watcho services and process your personal data in accordance with the IT Act, 2000 and other national and state laws which relate the processing of personal data. Please read the following carefully to understand our views and practices regarding your personal data. </p>
            <p>This Privacy Policy (hereinafter referred to as ‘Policy’) aims the make the user (“users/you”) aware of the information that is collected from the user(s) through the website <a href="mailto:hello@watcho.com">hello@watcho.com</a> (“Website”) and related Site(s), mobile applications (“App(s)”) and other online features. The Site(s)/ App(s) are offered by Dish Infra Service Private Limited and its subsidiaries or affiliated companies (collectively, “Dish Infra” or “we”, “us” and “our”).  </p>
            <p>
              This Policy explains the practices regarding the use and disclosure of certain information, including your personal information and by entering the Website you are consenting to the terms of this Policy and to our continued use of such collected information. By submitting your personal information to us, you shall be treated as having given your unconditional permission to us for processing your personal data as more particularly set out in this Policy.
            </p>
            <h3 className="list_item">What Information do we collect? </h3>
            <p>Information relating to: </p>
            <div className="ml15">
              <div className="lists">
                <div className="list_inside1">
                  <div className="lists mt10">
                    <ul>
                      <li>Your name, user name, age, gender, email address, postal address or postal code, Bank Account details (in selected cases), payment method(s), mobile/telephone number and other ancillary information you provide while creating an account on our Sites or App(s) and/or while signing-up to receive communications or materials from us, and/or otherwise submitting a form to us; </li>
                      <li>Transaction and payment information you choose to provide when purchasing a subscription/membership or service from us, whether on our Sites or Apps or elsewhere. </li>
                      <li>Survey Answers, Reviews, Ratings and other types of Feedback Provided, Account Settings or otherwise information provided to us through our service or elsewhere. </li>
                      <li>IP Address, Domain name aggregated to measure the number of visits, average time spent on the site, pages viewed, etc. any configuration or other information which is used to identify the type of device being used, device information (such as OS type or browser type), cookie IDs, referring / exit pages and URLs, interaction information (such as clickstream data), crash data and other similar technical data. </li>
                      <li> Your searched terms, interactions and views on ads and/or content and/or any other content or information that you submit, or upload on the Website/Apps/or any other platform of Watcho. </li>
                      <li>Your geographical location based on which we can improve our services. </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="list_item">How do we use the Information?  </h3>
            <p>We may use the information to:  </p>
            <div className="ml15">
              <div className="lists">
                <div className="list_inside1">
                  <div className="lists mt10">
                    <ul>
                      <li>provide you with services, and personalize your experience by setting up account and charging you for our paid services;  </li>
                      <li>to process and respond to varied requests, improve our operations and communicate with visitors about our as well as third party’s offers, products, services and businesses; </li>
                      <li>respond to your questions and concerns; </li>
                      <li>communicate with you about your account or transactions, and provide you with service-related communications, such as information about new features and policy updates; </li>
                      <li>operate, maintain, analyze, develop, update, upgrade and improve our Sites/App(s); </li>
                      <li>send you news, updates, promotions, product information and other marketing communications; </li>
                      <li>implement the activities described in our Cookie Policy. </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="list_item">Who we share information with?  </h3>
            <p>We may share the information you provide with other third party (ies) we have engaged to provide services for us or otherwise. We do not sell or lease our user’s information in any manner whatsoever. </p>
            <div className="ml15">
              <div className="lists">
                <div className="list_inside1">
                  <div className="lists mt10">
                    <ul>
                      <li>We may disclose information about you to our subsidiaries, our employees, and individuals who are our independent contractors that need to know the information in order to help us provide our Services or to process the information on our behalf.  </li>
                      <li>We may disclose information about you in response to a statutory notice, court order, or any other governmental request as the case may be.  </li>
                      <li>We may share the information you provide with other third party (ies) we have engaged to provide services for us or otherwise. These third party (ies) are contractually bound under a separate arrangement to use personal information we share with them only to perform the services we have engaged them to provide like payment providers that process your transaction information, fraud prevention services to analyze fraudulent payment transactions, postal and email delivery services, customer chat and email support services, registrars, registries etc. </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="list_item">How Secure is your Information?</h3>
            <div className="ml15">
              <div className="lists">
                <div className="list_inside1">
                  <div className="lists mt10">
                    <ul>
                      <li>We maintain strict physical, electronic, and administrative safeguards to protect your information from unauthorized or inappropriate access.   </li>
                      <li>We restrict access to information about you to those Dish Infra employees who need to know the information to respond to your inquiry or request. Employees who misuse personal information are subject to disciplinary action.   </li>
                      <li>We may conduct internal checks from time to time to ensure that your information is secure in all respect to the best of our potential.  </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <h3 className="list_item">What are Cookies and what do they do? </h3>
            <div className="lists">
              <div className="list_inside1">
                <div className=" mt10">
                  <p>A cookie is a tiny element of data sent from a website and stored on user’s computer/device by the web browser which such user is using.  </p>
                  <p>We use cookies primarily to remember you and your preferences and identify and track visitors, usage, and access preferences for our Services.  </p>
                </div>
              </div>
            </div>
            <span className="list_item"><i>For more detailed information about our use of cookies and as to how you can control the use of cookies, please Contact our IT Team through Contact Us Section. </i></span>
            <h3 className="list_item">What Choices you have as a User? </h3>
            <p>You can choose not to provide:</p>
            <div className="ml15">
              <div className="lists">
                <div className="list_inside1">
                  <div className="lists mt10">
                    <ul>
                      <li>the other account information </li>
                      <li>profile information other than name and e-mail address,    </li>
                      <li>Transaction and billing information.<br/>(Note: If you do not provide the above-mentioned information, you won’t be able to access/use all features of our Services) </li>
                      <li>Opt-Out from receiving promotional messages and e-mails. </li>
                      <li>Set Your Browser to Reject Cookies <br/>(Note: if you choose this setting, you may not be able to access certain features of our websites as it may not function appropriately without the help of cookies). </li>
                    </ul>
                  </div>
                </div>
              </div>
              <p> You have the ability to exercise certain controls with respect our collection and use of your information. You may request deletion of information that we hold about you by sending us an email to our support email address at <a href="mailto:hello@watcho.com">hello@watcho.com</a>. As we retain your information as long as necessary for providing you the services and to comply with our legal obligations, we shall review &amp; process your request and may ask you to verify your identity.  </p>
            </div>
            <h4 className="list_item">Legal Age to access the website?  </h4>
            <p>
              You must be a major as per the law of the land in order to subscribe to our services. Individual who is a minor may use services and watch content on the website under category of U or U/A, but they may do so only with the discretion, supervision, approval and guidance of a parent or legal guardian.
            </p>
            <h4 className="list_item">How to have access to your Information?  </h4>
            <p>
              You may review, edit and update the Information that you provide to us through this Website using “My Account” section provided on the Website.
            </p>
            <h4 className="list_item">How to Reach Us?  </h4>
            <p>
              If you have any questions about this privacy policy, please Contact Us at <a href="mailto:hello@watcho.com">hello@watcho.com</a>. We welcome your questions and suggestions about our privacy policy.
            </p>
            <h4 className="list_item">Changes to this Policy </h4>
            <p>We will update this Privacy Policy pursuant to changing legal or regulatory requirements. Please check this Policy regularly to inform yourself of any addition/deletion/modification/amendment of any nature whatsoever. Any site that you may connect to from here is not covered by this Policy. </p>
          </div>


        </div>
      </div>
      <Footer />

    </Fragment>
  )
}

export default Policy