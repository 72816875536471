import React, { useState,useRef } from 'react'
import './plans.css'
import { calculatePercentage, compareData, filterPlans, removeFromString } from '../../api/Functions';
import { Link } from 'react-router-dom';
import { pushGTMTAG } from '../../api/GTMTag';

const Index = ({expanPlan,handleClick, compareList}) => {

    const annulaRef = useRef(null);
    const monthlyRef = useRef(null);

  
  return (
    <div className={expanPlan?'plansWap show':'plansWap hide'} >
      <div className='headRow'>
        <div className='rowLeft'>
            <span className=''>Our<br /> Plans</span>
        </div>
        <div className='rowRight'>
        {
                compareList.planNames?.map((key) => {

                    return (<div className='planClms'>{removeFromString(['WATCHO'], key)}</div>)
                })
            }
           
        </div>
      </div>
     
      {
        compareList.planKeys?.map((data) => {
          
            return (
                <div className='contRow'>
                <div className='rowLeft'>
                    <img className='ottLogo' src={compareList.ottList[data].app.AppIconUrl} alt={data} />
                </div>
                <div className='rowRight'>
                {
                    compareList.planNames?.map((key) => {
                   
                        return ( <div className='checkmark'>
                        
                            {compareList.ottList[data].compare[key].SubscriptionPlanID ? <span><i className='fa fa-check'></i></span>  : ''}
                            
                                           
                    </div>)
                    })
                }
                
                            
                </div>
              </div>
            )
        })
    }
      
      


      <div className='contRow dashed'>
        <div className='rowLeft'>
            <span className=''>Apps Worth</span>
        </div>
        <div className='rowRight'>   
        {
                compareList.planNames?.map((key) => {

                    return (<div className='realPrice'>
                            <span>&#8377; {compareList.planPrices[key]?.SubscriptionDisplayPriceWithTax}</span>
                            </div>
                            )
                })
            }         
          
           
        </div>
      </div>
      <div className='contRow dashed'>
        <div className='rowLeft'>
            <span className=''>You Pay</span>
       
        </div>
        <div className='rowRight'> 
        {
                compareList.planNames?.map((key) => {

                    return (<div className='reducedPrice'>
                    <span>&#8377; {compareList.planPrices[key]?.SubscriptionPriceWithTax} <i className='info'onClick={() => handleClick(compareList.planPrices[key])} >i</i></span>
                    
                   </div>
                            )
                })
            }            
           
           
        </div>
      </div>

      <div className='contRow dashed border-0'>
        <div className='rowLeft'>
            <span className=''>You Save</span>
        </div>
        <div className='rowRight prcent'> 
        {
                compareList.planNames?.map((key) => {

                    return (
                        <div>{calculatePercentage(compareList.planPrices[key]?.SubscriptionDisplayPriceWithTax, compareList.planPrices[key]?.SubscriptionPriceWithTax)}%</div>
                       
                    )
                })
            } 
         
        </div>
      </div>
      
      <div className='contRow dashed border-0'>
        <div className='rowLeft'>
            
        </div>
        <div className='rowRight'>  
        {
                compareList.planNames?.map((key) => {

                    return (
                        <>
                        <Link onClick={() => pushGTMTAG(compareList.planPrices[key], 'buy_now')} to={`/planoffer/${(removeFromString(['WATCHO'], key)).trim().toLowerCase()}`} state={compareList.planPrices[key]} className='site-btn-default' >Subscribe</Link>
                        </>
                    )
                })
            }           
           
        </div>
      </div>

    </div>
  )
}

export default Index
