import React, { Fragment, useContext, useEffect, useRef, useState } from 'react'
import Confetti from 'react-confetti'
import './popups.css'
import OtpInput from 'react-otp-input';
import globalContext from '../../context/GlobalContext';
import { VaildPromoCode, encryptData, generateSubscription, generateSubscriptionRequestAPI, generateTokenAPI, getActiveSubcriptionPlan, sendOtpRequest, validateOtp } from '../../api/Activity';
import axios from 'axios';
import { calculateDiscount, getUserType } from '../../api/Functions';
import { billDeskSDK, billdeskCreateOrder } from '../../api/Billdesk';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { buyPlanTag, pushGTMTAG } from '../../api/GTMTag';
import { INITIATE_MID, IS_STAGING, LOCALSTORAGE_KEY, PAYTM_URL, RETURN_URL, SUBSCRIPTION_MID, TESTING_ENABLE, TESTING_MOBILE_NUMBERS } from '../../api/Variables';
const getCurrentDimension = () => {
    return {
        width: window.innerWidth,
        height: window.innerHeight
    }
}
function Checkout() {
    const location = useLocation();
    const orderState = location.state;
    const [otp, setOtp] = useState('');
    const [showForm, setIsShowForm] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [otpError, setIsOtpError] = useState(null);
    const [isCouponApplied, setIsCouponApplied] = useState(false);
    const [isCouponAppliedPopup, setIsCouponAppliedPopup] = useState(false);
    const [couponError, setCouponError] = useState(null);
    const [checkoutStep, setCheckoutStep] = useState(1);
    const [coupon, setCoupon] = useState('');
    const [verificationData, setVerificationData] = useState({ mobile: null, otp: null, term_condition: false, auto_renew: true, term_condition: true });
    const [verificationError, setVerificationError] = useState({ mobile: null, otp: null });
    const [screenSize, setScreenSize] = useState(getCurrentDimension());
    const [isActivePlan, setIsActivePlan] = useState(false);
    const [checkoutMessage, setCheckoutMessage] = useState(1);
    const [isButtonDisabled, setIsButtonDisabled] = useState({ 'send_otp': false, 'verify_otp': false, 'make_payment': true });
    const screeRef = useRef(null)
    const checkout = useContext(globalContext)
    const [ip, setIP] = useState('');
    const [paytmBody, setPaytm] = useState([])
    const [paytmDetails, setPaytmDetails] = useState({ 'action': '', 'body': [], 'head': '' })
    const [dataLayer, setDataLayer] = useState({ 'user_type': '', 'plan_duration': '', 'plan_amount': '', 'plan_name': '', 'plan_id': '' })
    const [orderSummaryVisible, setOrderSummaryVisible] = useState(false);
    const [isResendOTP, setIsResendOTP] = useState(false);
    const [dataForm, setDataForm] = useState('');
    const [orderError, setOrderError] = useState(null);
    const [tokenValue, setTokenValue] = useState(0);

    useEffect(() => {
        if (orderState == null) {
            window.location.href = "/";
        }
        else {
            window.scrollTo(0, 0)
            checkout.setCheckoutPlan(orderState)
            setDataLayer({ 'user_type': getUserType(), 'plan_duration': orderState.SubscriptionPlanDurationName, 'plan_amount': orderState.SubscriptionPriceWithTax, 'plan_name': orderState.SubscriptionPlanName, 'plan_id': orderState.SubscriptionPlanID })
            getData()
        }
    }, []);
    const setFormData = (value, type) => {
        let updatedValue = {};
        if (type == 'mobile') {
            if (/\D/g.test(value)) {
                value = value.replace(/\D/g, '');
            }
            if (value.length <= 10) {
                updatedValue = { mobile: value };
                setVerificationData(verificationData => ({
                    ...verificationData,
                    ...updatedValue
                }));
            }
        }
        else if (type == 'otp') {
            if (/\D/g.test(value)) {
                value = value.replace(/\D/g, '');
            }
            updatedValue = { otp: value };
            if (value.length === 4) {
                setIsDisabled(false)
            } else {
                setIsDisabled(true);
            }
            setVerificationData(verificationData => ({
                ...verificationData,
                ...updatedValue
            }));
        }
    }
    const disableEnableButton = (data) => {
        setIsButtonDisabled(buttonDisabled => ({
            ...buttonDisabled,
            ...data
        }));
    }
    const getOtp = async (resent_otp = false) => {
        var error = { mobile: '', otp: '' }
        setVerificationData(verificationData => ({
            ...verificationData,
            ...{ 'otp': '' }
        }));
        setVerificationError(verificationData => ({
            ...verificationData,
            ...{ otp: '' }
        }));
        disableEnableButton({ send_otp: true })
        if (verificationData.mobile == null) {
            error = { mobile: 'Please enter mobile number' }
        }
        else if (verificationData.mobile.length != 10) {
            error = { mobile: 'Mobile number is invalid' }
        }
        else {
            if (IS_STAGING) {
                if (verificationData.mobile == 1234567890) {
                    setCheckoutStep(2)
                }
                else {
                    error.mobile = 'Mobile number is invalid';
                }
            }
            else {
                if(resent_otp == true) {
                    pushGTMTAG(checkout.selectedPlan, 'signin_resent_otp')

                }
                else {
                    
                    pushGTMTAG(checkout.selectedPlan, 'signin_initiated')
                }
                let param = await encryptData({ "MobileNo": verificationData.mobile, "Source": "NDLP" });
                const appData = await generateTokenAPI(param);
                if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                    checkout.setAuthorizationToken(appData.Result)
                    const sendOtpAppData = await sendOtpRequest(param, appData.Result);
                    if (sendOtpAppData.ResultStatus && (sendOtpAppData.ResultStatus).toLowerCase() == 'success') {
                        error = { mobile: null }
                        setCheckoutStep(2)
                    }
                    else {
                        error = { mobile: sendOtpAppData.ResultMessage }
                    }
                }
                else {
                    error = { mobile: appData.ResultMessage }
                }
            }
        }
        setVerificationError(verificationData => ({
            ...verificationData,
            ...error
        }));
        disableEnableButton({ send_otp: false })
    }
    const verifyOtp = async () => {
        var error =  { otp: '' };
        setVerificationError(verificationData => ({
            ...verificationData,
            ...error
        }));
        setIsDisabled(true)
        if (IS_STAGING) {
            if (verificationData.otp == 1234) {
                setCheckoutStep(3)
                disableEnableButton({ make_payment: false })
            }
            else {
                error = { otp: 'Please enter correct otp' }
            }
        }
        else {
            let param = await encryptData({ "MobileNo": verificationData.mobile, "OTP": verificationData.otp, "Source": "NDLP" });
            const appData = await validateOtp(param, checkout.authToken);
            if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                checkout.setAuthorization(appData.Result)
                error = { otp: null }
                let param = await encryptData({ "OTTSMSID": appData.Result.OTTSMSID, "MobileNo": verificationData.mobile, "Source": "NDLP" });
                pushGTMTAG(checkout.selectedPlan, 'signin_completed', checkout.auth)
                const check_plan = await getActiveSubcriptionPlan(param, checkout.authToken);
                if (check_plan.ResultStatus && (check_plan.ResultStatus).toLowerCase() == 'success') {
                    if (appData.Result.CustomerCategory == 3) {
                        if ((check_plan.Result.ActivePlans).length > 0) {
                            setIsActivePlan(true)
                            setCheckoutMessage(3)
                            disableEnableButton({ make_payment: true })
                        }
                        else {
                            setIsActivePlan(false)
                            setCheckoutMessage(1)
                            disableEnableButton({ make_payment: false })
                        }
                    }
                    else {
                        if ((check_plan.Result.ActivePlans).length > 0) {
                            setIsActivePlan(true)
                            setCheckoutMessage(3)
                            disableEnableButton({ make_payment: true })
                        }
                        else {
                            setIsActivePlan(false)
                            setCheckoutMessage(2)
                            disableEnableButton({ make_payment: false })
                        }
                    }
                    if (TESTING_ENABLE && TESTING_MOBILE_NUMBERS.includes(verificationData.mobile)) {
                        setIsActivePlan(false)
                        setCheckoutMessage(1)
                        disableEnableButton({ make_payment: false })
                    }
                }
                setCheckoutStep(3)
            }
            else {
                error = { otp: appData.ResultMessage }
            }
        }
        setIsDisabled(false)
        setVerificationError(verificationData => ({
            ...verificationData,
            ...error
        }));
    }
    const applyCoupon = async () => {
        setCouponError(null)
        if (IS_STAGING) {
            if (coupon == 'watcho100') {
                setIsCouponApplied(true)
                var discount_value = calculateDiscount(100, checkout.selectedPlan.SubscriptionPriceWithTax);
                var final_price = Math.round(checkout.selectedPlan.SubscriptionPriceWithTax - discount_value)
                if (final_price <= 0) {
                    setTokenValue(1)
                }
                else {
                    setTokenValue(0)
                }
                checkout.setStateData({ 'discount': checkout.selectedPlan.SubscriptionPriceWithTax - final_price, price: final_price, coupon: '' }, 'coupon')
                setIsCouponApplied(true)
                setIsCouponAppliedPopup(true)
                setCouponError(null)
                const getData = setTimeout(() => {
                    setIsCouponAppliedPopup(false)
                }, 4000);
            }
        }
        else {
            if (checkoutStep == 3) {
                let param = await encryptData({
                    "MobileNo": checkout.auth['MobileNo'],
                    "OTTSMSID": checkout.auth['OTTSMSID'],
                    "PromoCode": coupon,
                    "PaymentGateway": "",
                    "SubscriptionPlanID": checkout.selectedPlan.SubscriptionPlanID,
                    "PromoCodeProvider": "DTH",
                    "Source": "NDLP"
                });
                const appData = await VaildPromoCode(param, checkout.authToken);
                if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                    pushGTMTAG(checkout.selectedPlan, 'apply_coupon', checkout.auth, coupon)
                    var discount_value = calculateDiscount(appData.Result.DiscountType.Value, checkout.selectedPlan.SubscriptionPriceWithTax);
                    if (parseFloat(discount_value) > parseFloat(appData.Result.MaxDiscountValue)) {
                        discount_value = parseFloat(appData.Result.MaxDiscountValue);
                    }
                    var final_price = Math.round(checkout.selectedPlan.SubscriptionPriceWithTax - discount_value)
                    if (final_price <= 0) {
                        setTokenValue(1)
                    }
                    else {
                        setTokenValue(0)
                    }
                    checkout.setStateData({ 'discount': checkout.selectedPlan.SubscriptionPriceWithTax - final_price, price: final_price, coupon: appData.Result.DiscountType.Value }, 'coupon')
                    setIsCouponApplied(true)
                    setIsCouponAppliedPopup(true)
                    setCouponError(null)
                    const getData = setTimeout(() => {
                        setIsCouponAppliedPopup(false)
                    }, 4000);
                }
                else if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'failed') {
                    setCouponError(appData.ResultMessage)
                    setCheckoutStep(3)
                }
                else {
                    setCouponError('Coupon is invalid')
                    setCheckoutStep(3)
                }
            }
        }
    }
    const removeCoupon = () => {
        setIsCouponApplied(false)
        setCoupon('')
        setTokenValue(0)
        checkout.setStateData({ 'discount': 0, price: checkout.selectedPlan.SubscriptionPriceWithTax }, 'coupon')
    }
    const getData = async () => {
        const res = await axios.get("https://api.ipify.org/?format=json");
        setIP(res.data.ip);
    };
    const finalCheckout = async () => {
        setOrderError(null)
        if (!isActivePlan) {
            disableEnableButton({ make_payment: true })
            setCheckoutStep(4)
            if (verificationData.auto_renew === true) {
                var data = {
                    "MobileNo": checkout.auth['MobileNo'],
                    "OTTSMSID": checkout.auth['OTTSMSID'],
                    "Source": "NDLP",
                    "SubscriptionPlanID": checkout.selectedPlan.SubscriptionPlanID,
                    "SubscriptionAmount": parseInt(checkout.selectedPlan.SubscriptionPriceWithTax),
                    "TransactionAmount": tokenValue > 0 ? parseInt(tokenValue) : parseInt(checkout.couponPrice.price),
                    "SubcriptionMode": "PG",
                    "SubscriptionPlanName": checkout.selectedPlan.SubscriptionPlanName,
                    "PromoCode": coupon,
                    "PromoCodeProvider": "",
                    "PromoCodeScratchCardNo": "",
                    "Discount": checkout.couponPrice.discount,
                    "RequestMode": "Instant",
                    "TransactionType": "N",
                    "AutoRenewalStatus": true,
                    "SubsPGRequest": {
                        "ReturnURL": RETURN_URL,
                        "DeviceInfo": {
                            "IP": ip,
                            "UserAgent": window.navigator.userAgent
                        }
                    }
                }
            }
            else {
                var data = {
                    "MobileNo": checkout.auth['MobileNo'],
                    "OTTSMSID": checkout.auth['OTTSMSID'],
                    "Source": "NDLP",
                    "SubscriptionPlanID": checkout.selectedPlan.SubscriptionPlanID,
                    "SubscriptionAmount": tokenValue > 0 ? parseInt(tokenValue) : parseInt(checkout.couponPrice.price),
                    "TransactionAmount": tokenValue > 0 ? parseInt(tokenValue) : parseInt(checkout.couponPrice.price),
                    "SubcriptionMode": "PG",
                    "SubscriptionPlanName": checkout.selectedPlan.SubscriptionPlanName,
                    "PromoCode": coupon,
                    "PromoCodeProvider": "",
                    "PromoCodeScratchCardNo": "",
                    "Discount": checkout.couponPrice.discount,
                    "RequestMode": "Instant",
                    "TransactionType": "N",
                    "AutoRenewalStatus": verificationData.auto_renew,
                    "SubsPGRequest": {
                        "ReturnURL": RETURN_URL,
                        // "ReturnURL" : "https://xojyysusvd.execute-api.ap-south-1.amazonaws.com/uat/v2/svod",
                        "DeviceInfo": {
                            "IP": ip,
                            "UserAgent": window.navigator.userAgent
                        }
                    }
                }
            }
            let param = await encryptData(data);
            const appData = await generateSubscriptionRequestAPI(param, checkout.authToken);
            if (appData.ResultStatus && (appData.ResultStatus).toLowerCase() == 'success') {
                checkout.setOrderDetails(appData.Result)
                pushGTMTAG(checkout.selectedPlan, 'continue_to_pay', checkout.auth, appData.Result)
                let param_enc = await encryptData({
                    "MobileNo": checkout.auth['MobileNo'],
                    "OTTSMSID": checkout.auth['OTTSMSID'],
                    "SubscriptionPlanID": checkout.selectedPlan.SubscriptionPlanID,
                    "AutoRenewalStatus": verificationData.auto_renew,
                    'token': checkout.authToken,
                    'planname': checkout.selectedPlan.SubscriptionPlanName,
                    'order': appData.Result,
                    "Source": "NDLP",
                    "CustomerCategory" : checkout.auth['CustomerCategory'],
                    'coupon_code' :coupon,
                    'plan' : checkout.selectedPlan
                });
                localStorage.setItem(LOCALSTORAGE_KEY, param_enc)
                if ((appData.Result.PGMode).toLowerCase() == "paytm") {
                    loadScript(verificationData.auto_renew)
                    var paytm = await generateSubscription(appData.Result, checkout.auth['OTTSMSID'], verificationData.auto_renew)
                    if ((paytm.body.resultInfo.resultStatus).toLowerCase() == 'txn_failure') {
                        setOrderError(paytm.body.resultInfo.resultMsg ?? null)
                        disableEnableButton({ make_payment: false })
                    }
                    else {
                        onScriptLoad(appData.Result, paytm)
                    }
                }
                else
                    if ((appData.Result.PGMode).toLowerCase() == "billdesk") {
                        billDeskSDK(appData.Result, data.AutoRenewalStatus)
                    }
            }
            else {
                if (appData.ResultStatus) {
                    setOrderError(appData.ResultMessage ?? null)
                    if (appData.ResultCode === -1000) {
                        setTimeout(function () {
                            setOrderError('')
                            disableEnableButton({ make_payment: false })
                        }, 60000);
                    }
                    else {
                        if (!isCouponApplied) {
                            setCheckoutStep(3)
                        }
                        disableEnableButton({ make_payment: false })
                    }
                }
                else {
                    setOrderError('Something went wrong')
                    setTimeout(function () {
                        setOrderError('')
                        disableEnableButton({ make_payment: false })
                    }, 60000);
                }
            }
        }
    }
    const autoRenew = (e) => {
        var updatedValue = { auto_renew: false }
        if (e.target.checked) {
            updatedValue = { auto_renew: true }
        }
        setVerificationData(verificationData => ({
            ...verificationData,
            ...updatedValue
        }));
    }
    function onScriptLoad(order, paytm) {
        var config = {
            root: "",
            flow: "DEFAULT",
            data: {
                "orderId": order.OrderID,
                "token": paytm.body.txnToken, 
                "tokenType": "TXN_TOKEN",
                "amount": order.ActualPayableAmount 
            },
            handler: {
                notifyMerchant: function (eventName, data) {
                    console.log("notifyMerchant handler function called");
                    console.log("eventName => ", eventName);
                    console.log("data => ", data);
                    if (eventName == 'SESSION_EXPIRED') {
                        location.reload();
                    }
                    if (eventName == 'APP_CLOSED') {
                        setOrderError('')
                        disableEnableButton({make_payment : false})
                    }
                },
            },
        };
        if (window.Paytm && window.Paytm.CheckoutJS) {
            window.Paytm.CheckoutJS.init(config)
                .then(function onSuccess() {
                    window.Paytm.CheckoutJS.invoke();
                })
                .catch(function onError(error) {
                    console.log("error => ", error);
                });
        }
    }
    const loadScript = (auto_renew) => {
        const script = document.createElement("script");
        script.async = true;
        if (auto_renew) {
            script.src = `${PAYTM_URL}/merchantpgpui/checkoutjs/merchants/${SUBSCRIPTION_MID}.js`;
        }
        else {
            script.src = `${PAYTM_URL}/merchantpgpui/checkoutjs/merchants/${INITIATE_MID}.js`;
        }
        document.head.appendChild(script);
    }
    const resentOTPAction = () => {
        getOtp(true);
        setIsResendOTP(true);
        const getData = setTimeout(() => {
            setIsResendOTP(false)
        }, 3000);
    }
    const termCondition = (e) => {
        var updatedValue = { term_condition: false }
        if (e.target.checked) {
            updatedValue = { term_condition: true }
        }
        setVerificationData(verificationData => ({
            ...verificationData,
            ...updatedValue
        }));
    }
    return (
        <Fragment >
            <Header />
            <div className="popupWrap show" >
                {dataForm}
                <div className='popupbody'>
                    <div className='popleftSec' ref={screeRef}>
                        <div className='summaryBox desktop_summery'>
                            <p>Order Summary</p>
                            <h3>You are Paying for <span>{(checkout.selectedPlan.SubscriptionPlanName)?.toLowerCase()}</span> </h3>
                            <div className='subsprc'>
                                <span>&#8377; {checkout.selectedPlan.SubscriptionPriceWithTax}</span>
                                <del>&#8377; {checkout.selectedPlan.SubscriptionDisplayPriceWithTax}</del>
                                <dd>({(checkout.selectedPlan.SubscriptionPlanDurationName)?.toLowerCase() == 'annual' ? 'Yearly' : (checkout.selectedPlan.SubscriptionPlanDurationName)} Subscription)</dd>
                            </div>
                            <div className='allpacks'>All {checkout.selectedPlan.SubscriptionApps ? (checkout.selectedPlan.SubscriptionApps).length : 0} OTTs in one magnificent pack.</div>
                        </div>
                        <div className='summaryBox'>
                            <p>Coupon Code</p>
                            <div className='coupon-form'>
                                {
                                    isCouponApplied ?
                                        <div className='with-code'>
                                            <span className='success-check'>
                                                <i className='fa fa-check'></i>
                                            </span>
                                            <input type='text' disabled className='' value={coupon} autoComplete='off' />
                                            <button type='button' onClick={removeCoupon}>Remove Code</button>
                                        </div>
                                        :
                                        <div className='without-code'>
                                            <img src={require('../../assets/images/icons/coupon-icon.png')} alt='%' />
                                            <input type='text' className='' placeholder='Enter Coupon Code' value={coupon} onChange={(e) => setCoupon(e.target.value)} autoComplete='off' />
                                            <button type='button' className={checkoutStep == 3 ? 'active-coupon' : 'inactive-coupon'} onClick={applyCoupon}>Apply</button>
                                        </div>
                                }
                            </div>
                            {couponError !== null ? <div className='errormsg coupon-error'>{couponError}</div> : null}
                            <div className='coupon-instruction'>*Verify your number first to redeem the coupon code.</div>
                        </div>
                        <div className='summaryBox'>
                            <p>Payment Summary</p>
                            <div className='paymemtsmr'>
                                <div className='row'>
                                    <span>Package Amount</span>
                                    <span>&#8377; {checkout.selectedPlan.SubscriptionPriceWithOutTax}</span>
                                </div>
                                <div className='row'>
                                    <span>Tax (+)</span>
                                    <span>&#8377; {checkout.selectedPlan.SubscriptionPriceWithTax - checkout.selectedPlan.SubscriptionPriceWithOutTax}</span>
                                </div>
                                {isCouponApplied ?
                                    <div className='row'>
                                        <span>Coupon (- {coupon})</span>
                                        <span>&#8377; {+checkout.couponPrice.discount}</span>
                                    </div>
                                    : ''
                                }
                                <div className='row total'>
                                    <span>Total Payment</span>
                                    <span>&#8377; {checkout.couponPrice.price}</span>
                                </div>
                                <button type='button' className={`site-btn-default form-btn make-payment-btn`} disabled={isButtonDisabled.make_payment} onClick={() => finalCheckout()}>Make Payment</button>
                                {orderError ? <p className='order-error'>{orderError}</p> : null}
                                {tokenValue > 0 ? <p className='token-charge'>Token charge of up to Rs.{tokenValue} for payment verification will be refunded</p> : null}
                            </div>
                        </div>
                        <div className={isCouponAppliedPopup ? 'success-popup show' : 'success-popup'}>
                            <div className='success-inner'>
                                <div className='success-container'>
                                    <div className='success-imgbox'>
                                        <img src={require('../../assets/images/success-pop.png')} alt="succes" />
                                    </div>
                                    <h3>{coupon}</h3>
                                    <div className='success-subtext'>Successfully Applied!</div>
                                    <div className='success-upto'>you save upto {checkout.couponPrice.coupon}% off on the selected plan</div>
                                </div>
                            </div>
                            <Confetti width={screenSize.width} height={screenSize.height} />
                        </div>
                    </div>
                    <div className='summaryBox mobile_summery'>
                        <ul className='accordion'>
                            <li className={orderSummaryVisible ? 'active' : ''}>
                                <p className='link' onClick={() => setOrderSummaryVisible(!orderSummaryVisible)}>Order Summary {orderSummaryVisible ? <i className="fa fa-angle-left"></i> : <i className="fa fa-angle-right"></i>} </p>
                                <ul className='submenu'>
                                    <li>
                                        <h3>You are Paying for <span>{(checkout.selectedPlan.SubscriptionPlanName)?.toLowerCase()}</span> </h3>
                                        <div className='subsprc'>
                                            <span>&#8377; {checkout.selectedPlan.SubscriptionPriceWithTax}</span>
                                            <del>&#8377; {checkout.selectedPlan.SubscriptionDisplayPriceWithTax}</del>
                                            <dd>({(checkout.selectedPlan.SubscriptionPlanDurationName)?.toLowerCase() == 'annual' ? 'Yearly' : (checkout.selectedPlan.SubscriptionPlanDurationName)} Subscription)</dd>
                                        </div>
                                        <div className='allpacks'>All {checkout.selectedPlan.SubscriptionApps ? (checkout.selectedPlan.SubscriptionApps).length : 0} OTTs in one magnificent pack.</div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='poprightSec'>
                        <div className='subscriptionForm'>
                            <h2>Smart Choice!</h2>
                            <p>The World Of Watcho Shall Be Yours Shortly</p>
                            <div className='paysteps'>
                                <ul className={checkoutStep == 3 || checkoutStep == 4 ? 'step-three' : 'step-two'}>
                                    <li className='previous-step'>
                                        <span className='dots fill'></span>
                                        <div>Select<br /> a plan</div>
                                    </li>
                                    <li className={checkoutStep == 2 || checkoutStep == 1 ? 'active-step' : 'previous-step'}>
                                        <span className={`dots ${checkoutStep == 1 || checkoutStep == 2 ? 'next-step' : 'fill'}`}></span>
                                        <div>Verify your<br />  number</div>
                                    </li>
                                    <li className={checkoutStep == 3 || checkoutStep == 4 ? 'active-step' : 'next-stepper'}>
                                        <span className={`dots ${checkoutStep == 3 ? 'next-step' : (checkoutStep == 4 ? "fill" : 'empty')}`}></span>
                                        <div>Make<br />  payment</div>
                                    </li>
                                </ul>
                            </div>
                            <div className={checkoutStep == 1 ? 'watchform ' : 'watchform stepOne'}>
                                <h3 className='form-subheading'>
                                    Enter your mobile number to receive OTP
                                </h3>
                                <div className='fieldsWrap'>
                                    <fieldset>
                                        <legend>Country Code</legend>
                                        <div className='drop-list'>
                                            <img src={require('../../assets/images/flags/flag-in.png')} alt='IN' />
                                            <span>+91</span>
                                        </div>
                                    </fieldset>
                                    <fieldset >
                                        <legend>Mobile Number</legend>
                                        <input type='text' placeholder='Enter Mobile Number' className='input-field' value={verificationData.mobile} onChange={e => { setFormData(e.target.value, 'mobile') }} />
                                        {verificationError.mobile !== null ? <div className='errormsg'>{verificationError.mobile}</div> : null}
                                    </fieldset>
                                </div>
                                <div className='form-check'>
                                    <input type='checkbox' className='form-check-input' value='' id='terms' checked={verificationData.term_condition} onChange={(e) => { termCondition(e) }} />
                                    <label >I am over 18, and I agree to the above conditions and the<br />
                                        <Link to='/term-use'>Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link></label>
                                </div>
                                <div className='form-check'>
                                    <input type='checkbox' className='form-check-input form-check-input-disabled' disabled checked={verificationData.auto_renew} value='' id='renewsubs' />
                                    <label>Auto Renew Your Subscription</label>
                                </div>
                                <button type='button' className='site-btn-default form-btn' disabled={isButtonDisabled.send_otp} onClick={getOtp}>Get OTP</button>
                            </div>
                            <div className={checkoutStep == 2 ? 'watchform stepTwo' : 'watchform stepTwo hide'}>
                                <h3 className='form-subheading'>
                                    Verify the OTP
                                </h3>
                                <span className='subtext'>The code has been sent to you on your mobile number (+91) {verificationData.mobile}</span>
                                <div className={`otpContainerWrap ${verificationError.otp !== null && verificationError.otp !== '' ? 'otp-error' : ''}`}>
                                    <OtpInput
                                        value={verificationData.otp}
                                        onChange={e => { setFormData(e, 'otp') }}
                                        inputStyle={(otpError === null) ? 'otpbox' : otpError + ' otpbox'}
                                        containerStyle='otpContainer'
                                        numInputs={4}
                                        onKey
                                        renderInput={(props) => <input {...props} />}
                                    />
                                    {verificationError.otp !== null ? (<div className='errormsg'>
                                        {verificationError.otp}
                                    </div>) : null}
                                </div>
                                <div className='mb-30'>
                                    <span className='subtext subtext-resend'>Haven't received the verification code? <span>  <button className='inline-btn' type='button' onClick={resentOTPAction}>Resend OTP</button> or <button className='inline-btn' type='button' onClick={() => setCheckoutStep(1)}>Re-Enter Mobile Number</button></span> </span>
                                    {isResendOTP ? <><br /><span>Otp is successfully sent</span></> : ''}
                                </div>
                                <button disabled={isDisabled} type='button' className='site-btn-default form-btn' onClick={verifyOtp}>Verify OTP</button>
                            </div>
                            <div className={checkoutStep == 3 || checkoutStep == 4 ? 'watchform stepThree' : 'watchform stepThree hide'}>
                                <h3 className='form-subheading'>
                                </h3>
                                <span className='subtext verify-mobile'> <span className='block_sec'>  Your mobile number </span> <span className='subtext-verified' >
                                    (+91) {verificationData.mobile} is verified</span>
                                    <span className='success-check desktop-success-check1'>
                                        <i className='fa fa-check'></i>
                                    </span>
                                </span>
                                <div className='otpContainerWrap'>
                                    <div className=''>
                                    </div>
                                </div>
                                <div className='mb-30'>
                                    {
                                        checkoutMessage == 3 ?
                                            <span className='subtext white hello-again'>Hello there! <br /> You are all set, start binging on your favorites now! For any changes in the plan click the <a className='redirect-url' href='https://www.watcho.com/'>watcho.com</a> and login. If you wish to start a new connection, simply try with a different number, Happy Streaming! </span>
                                            :
                                            checkoutMessage == 2 ?
                                                <span className='subtext white hello-again'>Hello Again!<br /> It’s good to have you back! You have already availed the complimentary offer on this number. Please complete your payment before you get started. Happy Streaming!</span>
                                                :
                                                <span className='subtext white hello-again'>Welcome to Watcho!<br />Complete your payment before you start streaming! Also, did we mention that you are eligible for the Watcho 1-month complimentary offer? Happy Streaming!</span>
                                    }
                                </div>
                                <div className='payment-note'>*{`For Annual and < Rs.99 packs are not applicable for BOGO`}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default Checkout